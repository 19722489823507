import STATUS from 'common/inspection/status';

const initialState = {
  inspections: {},
  reports: {}
};

const state = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_INSPECTION':
      // Overwrite copy only if status != SYNCING
      const foundInspection = state.inspections[action.inspectionId];
      if (foundInspection) {
        if (foundInspection.status === 'SYNCING') {
          return state;
        } else {
          return {
            ...state,
            inspections: {
              ...state.inspections,
              [action.inspectionId]: {
                inspectionId: action.inspectionId,
                createdAt: action.createdAt,
                updatedAt: action.updatedAt,
                inspector: action.inspector,
                data: action.data,
                mf: action.mf,
                mfData: action.mfData,
                status: action.status,
                priceBook: action.priceBook,
              }
            },
            reports: {
              ...state.reports,
              [action.inspectionId]: {
                ...action.report,
                id: action.inspectionId,
                createdAt: action.createdAt,
                inspector: action.inspector,
              }
            }
          };
        }
      }

      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            inspectionId: action.inspectionId,
            createdAt: action.createdAt,
            updatedAt: action.updatedAt,
            inspector: action.inspector,
            data: action.data,
            mf: action.mf,
            mfData: action.mfData,
            status: action.status,
            priceBook: action.priceBook,
          }
        },
        reports: {
          ...state.reports,
          [action.inspectionId]: {
            ...action.report,
            id: action.inspectionId,
            createdAt: action.createdAt,
            inspector: action.inspector,
          }
        }
      };
    case 'UPDATE_INSPECTION':
      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            ...state.inspections[action.inspectionId],
            data: {
              ...state.inspections[action.inspectionId].data,
              ...action.data
            },
            updatedAt: (new Date()).toISOString(),
            status: STATUS.SYNCING.value
          }
        }
      };
    case 'UPDATE_PRICEBOOK':
      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            ...state.inspections[action.inspectionId],
            priceBook: {...action.priceBook},
          },
        },
      }
    case 'SET_INSPECTION_STATUS':
      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            ...state.inspections[action.inspectionId],
            status: action.status,
          },
        },
      };
    case 'SET_PHOTO_URL':
      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            ...state.inspections[action.inspectionId],
            data: {
              ...state.inspections[action.inspectionId].data,
              [action.fieldId]: state.inspections[action.inspectionId].data[
                action.fieldId
              ].map((url) => {
                if (url === action.dataUrl) {
                  return action.url;
                }

                return url;
              })
            }
          }
        }
      };
    case 'UPDATE_REPORT':
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.inspectionId]: {
            ...state.reports[action.inspectionId],
            ...action.report
          }
        }
      };
    case 'UPDATE_MULTIFLUE_INSPECTION':
      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            ...state.inspections[action.inspectionId],
            mfData: {
              ...state.inspections[action.inspectionId].mfData,
              ...action.mfData
            },
            updatedAt: (new Date()).toISOString(),
            status: STATUS.SYNCING.value
          }
        }
      };
    case 'UPDATE_MULTIFLUE_STATUS':
      return {
        ...state,
        inspections: {
          ...state.inspections,
          [action.inspectionId]: {
            ...state.inspections[action.inspectionId],
            mf: action.mfStatus,
            updatedAt: (new Date()).toISOString(),
            status: STATUS.SYNCING.value
          }
        }
      };
    default:
      return state;
  }
};

export default state;
