import sendToSentry from "./sendToSentry";

const actionFrequencyTracker = (store) => {
  const actionCount = {};
  const THRESHOLD = 200;
  const RESET_INTERVAL = 60000;

  setInterval(() => {
    Object.keys(actionCount).forEach((type) => {
      actionCount[type] = 0;
    })
  }, RESET_INTERVAL);

  return (next) => (action) => {
    const { type } = action;
    actionCount[type] = (actionCount[type] || 0) + 1;

    if (actionCount[type] > THRESHOLD) {
      console.error(`Potential infinite dispatch detected for action: ${type}`);

      sendToSentry({
        message: 'Potential infinite dispatch detected',
        actionType: type,
        count: actionCount[type],
        store
      })
    }

    return next(action);
  }
};

export default actionFrequencyTracker;