import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import * as Sentry from '@sentry/react';
import debounce from 'lodash.debounce';

import app from './reducers/inspectionsReducer';
import auth from './reducers/authenticationReducer';
import notifications from './reducers/notificationsReducer';
import dasharama from './reducers/dasharamaReducer';
import { saveAllInspections } from 'actions/inspections';
import actionFrequencyTracker from 'utils/actionFrequencyTracker';

// Sentry Redux Monitoring
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// Create the Redux store using Redux Toolkit
const store = configureStore({
  reducer: {
    app,
    auth,
    notifications,
    dasharama
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(actionFrequencyTracker),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  devTools: {
    trace: true, // Enables trace feature in Redux DevTools
  },
});

const saveStore = () => {
  const appState = store.getState().app;
  if (appState) {
    store.dispatch(saveAllInspections(appState));
  } else {
    console.error('App state is undefined or null');
  }
};

store.subscribe(debounce(saveStore, 2500));

export default store;
